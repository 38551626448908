import React from "react";

import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const Contact = () => {
  return (
    <>
      <Navbar
        SeoTitle="Contact us for Worcester to/from Boston Logan Airport transportation - Worcester Airport Taxi"
        SeoDesc="Contact us for Worcester to/from Boston Logan Airport transportation. We are your leading Worcester Airport Transportation Company. Call us today for booking 508) 519-1755 without any hassle."
      />
      <div className="w-[calc(100vw - 100%)] pt-[70px]   flex flex-col items-center bg-[#f5f5f5]">
        <h1 className="text-center font-[700] mb-[50px] text-[36px] leading-[54px] ">
          Contact Us
        </h1>

        <div className="w-[80%] mb-[60px] contact__page">
          <div className="grid grid-cols-2  w-full   gap-10">
            <form>
              <div className="flex flex-wrap -mx-3  ">
                <div className="w-full   px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    placeholder="Jane"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    E-mail
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="email"
                    type="email"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    Message
                  </label>
                  <textarea
                    className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                    id="message"
                  ></textarea>
                </div>
              </div>
              <div className="md:flex md:items-center">
                <div className="md:w-1/3">
                  <button
                    className="shadow bg-[#FF9F43] w-full rounded-[10px] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                    type="button"
                  >
                    Send
                  </button>
                </div>
                <div className="md:w-2/3"></div>
              </div>
            </form>
            <div className="contact_info">
              <h4 className="font-[700] mb-[30px] text-[22px] ">
                Worcester Airport Taxi and Car Service
              </h4>
              <p className="  mb-[10px]  ">
                Worcester Airport Taxi and Car Service is a reliable and
                affordable taxi service that provides luxurious and comfortable
                travel throughout the entire Worcester area. Worcester Airport
                Taxi and Car Service offer affordable taxi hire from family
                saloon cars to people carriers. Whether it is an airport trip,
                long distance journey, business travel, prom night, doctor's
                appointment, or a family gathering we offer a helpful and
                friendly service every time.
              </p>
              <p>
                A taxi company in Andover ma that you can trust for your ride.
              </p>
              <h5 className="font-[700] mb-[10px] mt-[20px] text-[18px] ">
                Contact Information
              </h5>
              <p className="font-[500] mb-[10px]  text-[16px] ">
                Worcester. MA
              </p>
              <p className="font-[500] mb-[10px]  text-[16px] ">
                Phone: <a href="tel:9782962776">(508) 519-1755</a>
              </p>
              <p className="font-[500] mb-[10px]  text-[16px] ">
                Email:
                <a href="mailto:info@worchester.com">
                  info@worcesterairporttaxi.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d188937.6038891707!2d-71.807738!3d42.27532!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e406585a2a8b0d%3A0x9e137dd87fca4d6d!2sWorcester%2C%20MA%2C%20USA!5e0!3m2!1sen!2snp!4v1665557987963!5m2!1sen!2snp"
        width="100%"
        height="450" 
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <Footer />
    </>
  );
};
export default Contact;
