import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Testimonial from "../Components/Testimonial";

const About = () => {
  return (
    <>
      <Navbar
        SeoTitle="Boston Logan Airport Taxi to Worcester | Worcester Airport Taxi"
        SeoDesc="Worcester Airport Taxi and Car Service provide transportation services to and from Worcester to all local airports including Boston's Logan International airport. Call us today for booking (508) 519-1755"
      />
      <div className="w-[calc(100vw - 100%)] pt-[70px]   flex flex-col items-center bg-[#f5f5f5]">
        <h1 className="text-center font-[700] mb-[30px] text-[36px] leading-[54px] ">
          How we Work
        </h1>
        <h4 className="font-[500] text-[24px] pl-[35px] pr-[35px] mb-[30px] leading-[44px] ">
          About Logan Airport to Worcester taxi, town car and limo services
        </h4>
        <p className="w-[90%] mb-[20px]">
          Worcester Airport Taxi and Car Service purvey the most economical,
          convenient and safe way to get to and from the Worcester airport. With
          flexible hours and skilled, professional drivers, we will get you
          where you need to be, right on time and in total comfort.
        </p>
        <p className="w-[90%] mb-[20px]">
          Worcester Airport Taxi and Car Service provide transportation services
          to and from Worcester to all local airports including Boston's Logan
          International airport. Whether you need a Boston Logan car service to
          Worcester or executive transportation in Grafton, Worcester Airport
          Taxi and Car Service has you covered.
        </p>
        <p className="w-[90%] mb-[20px]">
          We have a range of fleets including Sedans, Minivans and SUVs that can
          be pre-booked to serve you in any of your ground transportation needs.
          All our driver's track record are checked and personally verified.
          They always carry valid licenses with permits to operate commercially
        </p>
        <p className="w-[90%] ">
          Relax and book your trip with confidence. Call us or message us and we
          will come to your doorstep at the appointed time to serve you.
        </p>
      </div>
      <Testimonial />
      <Footer />
    </>
  );
};
export default About;
