import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Pages/About";
import AreaWeCover from "./Pages/AreaWeCover";
import Contact from "./Pages/Contact";
import Fleet from "./Pages/Fleet";
import Index from "./Pages/Index";
import Privacy from "./Pages/Privacy";
import ServiceArea from "./Pages/ServiceArea";
import Terms from "./Pages/Terms";
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/worcester-airport-taxi"
          element={
            <AreaWeCover
              SeoTitle="Taxi & Car Service from Boston to Worcester, MA - Worcester Airport Taxi"
              SeoDesc="We offer services for both business and individuals and can transport you from Worcester to Logan airport or anywhere with the minimum fuss.Call (508) 519-1755 for bookings."
              MainTitle="WORCESTER AIRPORT TAXI"
              SubTitle="Affordable Worcester Airport Taxi Service"
              para1="Get to Worcester right way with our private hire taxi services. We offer a range of vehicles to tailor to different budgets and requirements. Whatever your individual needs, we’ll be able to take care of them. We offer services for both business and individuals and can transport you from Worcester to Logan airport or anywhere with the minimum fuss."
              para2="Whether you are here for work or leisure, our professional drivers will ensure that you reach your destination safely and quickly. With our Worcester airport taxi, you can be sure of reaching the airport, hotel, event destination or anywhere in Worcester on time. We also provide baby seats, infant seat and booster seat for the safety of your children as per request. Our drivers are professional, extensive experienced and have undergone the necessary training, health and working with children checks as well."
              para3="Whether you are arriving at or departing from Logan Airport to Worcester or from Worcester to Logan airport in a group or all alone, our comprehensive fleet of vehicles will be able to accommodate your request. For more information about the Worcester Airport Taxi service call us at (508) 519-1755"
              para4=""
              para5=""
            />
          }
        />
        <Route
          path="/auburn-airport-taxi"
          element={
            <AreaWeCover
              SeoTitle="Auburn Taxi - Auburn Airport Taxi - Auburn Flat Rate Taxi"
              SeoDesc="Best Taxi Service for Auburn Airport Taxi & Transfer. Make your holiday or any journey exciting and stress-free."
              MainTitle="AUBURN AIRPORT TAXI"
              SubTitle="Best Taxi Service for Auburn Airport Transfer"
              para1="Make your holiday or any journey exciting and stress-free. We offer you stress-free auburn airport taxi service from your hotel or house to anywhere in Auburn. We'll pick you up at a time of your convenience and have you at the airport with time to spare."
              para2="We are one of Auburn leading specialists in executive airport transfers, providing chauffeur driven vehicles throughout the capital and beyond for over 15 Years. All of our vehicles are well maintained, cleaned and serviced all the times and fully insured. We will always provide you with your chauffeurs mobile number prior to your arrival, and your professional chauffeur will also be provided with your mobile number, so your Meet and Greet is seamless. Our Auburn drivers are professional, extensive experienced and are accredited with the Ministry of Transport and have undergone the necessary training, health and working with children checks as well."
              para3="We also provide baby seats, infant seat and booster seat for the safety of your children as per request. Our drivers are professional, extensive experienced and have undergone the necessary training, health and working with children checks as well."
              para4="We have a dedicated team of staff available 24/7 to deal with your booking and provide a prompt fast reliable auburn taxi service. We pride ourselves on reliability and professional conduct in all aspects of our work. For an obligation free quote and friendly professional service contact us today."
   
            />
          }
        />
        <Route
          path="/shrewsbury-airport-taxi"
          element={
            <AreaWeCover
              SeoTitle="Shrewsbury Airport Taxi Service - Shrewsbury Airport Car - Worcester Airport Taxi"
              SeoDesc="Looking for Shrewsbury Airport Taxi and Car Service? Look no further than Worcester Airport Taxi, We are your #1 Transportation Company in Shrewsbury. Call us today and get a free quote  (508) 519-1755"
              MainTitle="SHREWSBURY AIRPORT TAXI"
              SubTitle="Reliable Shrewsbury Taxi Service"
              para1="We provide high-quality corporate and private airport taxi services from Logan Airport to Shrewsbury anywhere. We offer the best reliable and safest to and from Shrewsbury airport taxi service to all our customers at a competitive price. Offering meets and greets service on your arrival, our drivers wait in the airport arrival hall with your name on the signboard."
              para2="All our vehicles are comprehensively insured and maintained to the highest standard. Our immaculate ranges of vehicles are always in perfect condition and our experienced staffs are very carefully chosen for their driving skills and wide-ranging route knowledge. We regularly check our driver's and our vehicle to ensure all of our vehicles are clean and meet safety standards. Our Shrewsbury driver is always friendly and +ready to give you hand if you need any help."
              para3="If you are looking for a taxi to any airport and want an executive transfer service, then read all about our airport transfers in Shrewsbury, or feel free to get in contact with us, either by calling +1 617-366-2855 or by using our online taxi booking service."
              para4="" 
            />
          }
        />
        <Route
          path="/leicester-airport-taxi"
          element={
            <AreaWeCover
              SeoTitle="Leicester Airport Taxi & Car Service - Worcester Airport Taxi"
              SeoDesc="Book  Leicester Airport Taxi & Car Service with us and give us the chance to serve you. We are the best Airport Transportation Company in  Leicester. Call us today for booking (508) 519-1755"
              MainTitle="LEICESTER AIRPORT TAXI"
              SubTitle="We specialize in airport taxis service from Leicester to all major airports."
              para1="Our budget vehicles provide cost-effective ways to get to and from Leicester which is more competitive and comfortable than using public transport, especially when you’re travelling with lots of luggage. "
              para2="Our competitive fleet consists of Standard Car, Estate and MPV to accommodate any request. We have highly motivated and trained staff and unparalleled levels of customer service that is all about developing long-term relationships with our clients. We offer a fixed airport taxi rate, so there aren’t any hidden charges! No extra charges for waiting or delays. We will track your flight so our Leicester airport taxi will be there ready and waiting."
              para3="Our drivers are professionally trained to provide safe and courteous service. We have a dedicated team of staff available 24/7 to deal with your Leicester booking queries and provides a prompt fast reliable service."
              para4="For more information about airport transfer, Leicester or Leicester airport Taxi fare contact us today at (508) 519-1755. Our friendly staff will be more than happy to assist you with your requirement."
   
            />
          }
        />
       
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/fleet" element={<Fleet />} />
        <Route path="/service-area" element={<ServiceArea />} />
        <Route path="/about" element={<About />} />
        <Route path="/" element={<Index />} />
      </Routes>
    </Router>
  );
}

export default App;
