import React from "react";

import Footer from "../Components/Footer";
import HotOffers from "../Components/HotOffers";
import Navbar from "../Components/Navbar";

const Fleet = () => {
  return (
    <>
      <Navbar
        SeoTitle="Our Fleet |SUV, Sedan, Minivan| Worcester Airport Taxi & Car Service"
        SeoDesc="Wide range of fleets ranging from Corporate sedan to 10-12 pax van available that suits your corporate needs. Call us today for booking  (508) 519-1755 without any hassle."
      />
      <HotOffers />
      <div className="pb-[100px]"></div>
      <Footer />
    </>
  );
};
export default Fleet;
