import React from "react";

import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const Terms = () => {
  return (
    <>
      <Navbar
        SeoTitle="TERMS AND CONDITIONS|| Worcester Airport Taxi & Car Service"
        SeoDesc="TERMS AND CONDITIONS|| Worcester Airport Taxi & Car Service"
      />
      <div className="w-[calc(100vw - 100%)] pt-[70px]   flex flex-col items-center bg-[#f5f5f5]">
        <h1 className="text-center font-[700] mb-[50px] text-[36px] leading-[54px] ">
          TERMS AND CONDITIONS
        </h1>

        <div className="w-[80%] mb-[60px]">
          <ul class="list-disc list-outside">
            <li className="mb-[15px]">
              We accept all type of Credit Card.{" "}
              <strong className="blue_txt">WORCESTER AIRPORT TAXI &amp; CAR SERVICES</strong> (DBA)
              is Incorporated by <strong className="blue_txt">Logan Airport Car, Inc</strong>.
            </li>
            <li className="mb-[15px]">
            
                "LOGAN AIRPORT CAR, INC" will appear on the  <strong className="blue_txt"> Credit Card / PayPal
                billing statement.
              </strong>
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Service:</strong> We provide 24/7 Airport Transportation -
              except State of Emergency.
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Payment Option:</strong>
              &nbsp;<strong className="blue_txt">PayPal or Credit Card</strong>. An option of payment
              must require to complete online booking.
            </li>
            <li className="mb-[15px]">
              All PayPal payment goes to under corporate email to
              :(loganairportcarservice@gmail.com).
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Luggage Restriction:</strong> There are maximum luggage
              restrictions depending on the&nbsp;vehicle Capacity. Please check
              your vehicle luggage capacity&nbsp;from the fleet/Fare Quote page.
              Any additional Luggage may cost each $3.00 – Please clarify the
              Luggage amount while booking.&nbsp;
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Changes:</strong> Change/Modify/Cancellation&nbsp;is free
              of cost before 6 hours of actually scheduled pickup. Please simply
              email 
              <a href="mailto:info@worcesterairporttaxi.com">
                 info@worcesterairporttaxi.com 
              </a>
              for the change request.
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Refund:</strong> Any refund Paid by{" "}
              <strong className="blue_txt">PayPal/Credit</strong> Card will be processed within 24
              hours.
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">No-Show:</strong> In case of passenger no-show. A no-show
              (<strong className="blue_txt">full fare</strong>) will occurred, no refund will be
              allowed. Passenger must contact us –
              or-&nbsp;email&nbsp;for&nbsp;reschedule/change/cancel
             
              an existing reservation 6 hours before scheduled pickup.&nbsp;
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Cancellation:</strong> Cancellation before 6 (SIX) hours -
              will be refunded in full. Less than 6(SIX) hour a refund will not
              be allowed. But you will be honored&nbsp;to change/modify the
              existing reservation for next use.
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Reservation:</strong>
              &nbsp;
              <strong className="blue_txt">(DAY)&nbsp;From 8:00 A.M&nbsp;- 8:00 P.M.</strong> Online
              Reservation Required at least 4-hour advance to schedule a Car.{" "}
              <strong className="blue_txt">(NIGHT) From 8:00 PM - 8:00 AM</strong>
              &nbsp;required minimum 8:00 hours for online reservation.{" "}
              <strong className="blue_txt">Need</strong>&nbsp;
              <strong className="blue_txt">
                Urgent Booking, Please&nbsp;Call us (508) 519-1755.
              </strong>
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Tolls/Fees:</strong>
              All Tolls &amp; Charges are Included in fare as per Airport
              Authority Rules
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">Waiting Charge</strong>
               : Waiting charge is $1.00 per minutes. 
            </li>
            <li className="mb-[15px]">
              <strong  className="blue_txt">State Emergency:</strong> In case of State Emergency or
              snow Storms or heavy snowfall, we might have to cancel the
              service, we will contact passenger before cancellation.
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">From Airport Pickup Waiting Time:</strong>
              &nbsp;Airport Pickup - the driver will wait up to 1 hours from the
              actual flight arrival/landing time. If you need more than 1 (ONE)
              hours - Please contact driver, if you need more time or No-Show
              may occurred.
            </li>
            <li className="mb-[15px]">
              <strong className="blue_txt">To Airport Pickup Waiting Time:</strong> Local pickup -
              the driver will wait up to&nbsp;15 minutes.&nbsp;Please contact
              driver, if you need more time. Please contact driver, if you need
              more time or No-Show may occurred.
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Terms;
