import React from "react";
import Benefits from "../Components/Benefits";
import Footer from "../Components/Footer";
import HotOffers from "../Components/HotOffers";
import HWW from "../Components/HWW";
import Main from "../Components/Main";
import Navbar from "../Components/Navbar";
import Testimonial from "../Components/Testimonial";

const Index = () => {
  return (
    <>
      <Main>
        <Navbar
          SeoTitle="Worcester Airport Taxi & Car Service - Reliable Car Service"
          SeoDesc="Worcester Airport Taxi and Car Service is a reliable and affordable taxi service that provides luxurious and comfortable travel throughout the entire Worcester area. Worcester Airport Taxi and Car Service offer affordable taxi hire from family saloon cars to people carriers. "
        />
      </Main>
      <HotOffers />
      <HWW />
      <Benefits />
      <Testimonial />
      <Footer />
    </>
  );
};

export default Index;
