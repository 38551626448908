import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const AreaWeCover = (props) => {
  return (
    <>
      <Navbar SeoTitle={props.SeoTitle} SeoDesc={props.SeoDesc} />
      <div className="w-[calc(100vw - 100%)] pt-[70px]   flex flex-col items-center bg-[#f5f5f5]">
        <h1 className="text-center font-[700] mb-[30px] text-[36px] leading-[54px] ">
          {props.MainTitle}
        </h1>
        <h4 className="text-left font-[500] text-[24px] pl-[35px] pr-[35px] mb-[30px] leading-[54px] ">
          {props.SubTitle}
        </h4>
        {props.para1 && <p className="w-[90%] mb-[20px]">{props.para1}</p>}
        {props.para2 && <p className="w-[90%] mb-[20px]">{props.para2}</p>}
        {props.para3 && <p className="w-[90%] mb-[20px]">{props.para3}</p>}
        {props.para4 && <p className="w-[90%] mb-[20px]">{props.para4}</p>}
      </div>
      <div className="w-[calc(100vw - 100%)] pt-[70px] pb-[70px]    bg-[#fff]">
        <h4
          style={{ margin: "0 auto" }}
          className=" w-[90%] text-[#273A89]  font-[500] text-[24px] pb-[30px] leading-[34px] "
        >
          Frequently provided taxi cab, town car, suv, van and minivan services
          from the following area to Boston Logan International airport.
        </h4>
        <ul
          class="list-disc  w-[90%] mt-[40px] pl-[20px]"
          style={{ margin: "0 auto", columnCount: "2" }}
        >
          <li className="mb-[10px] ">
            <a className="font-[700] text-[18px]  text-[#FF9F43] " href="">
              Worcester Airport Taxi
            </a>
          </li>
          <li className="mb-[10px] ">
            <a className="font-[700] text-[18px]  text-[#FF9F43] " href="">
              Auburn Airport Taxi
            </a>
          </li>
          <li className="mb-[10px] ">
            <a className="font-[700] text-[18px]  text-[#FF9F43] " href="">
              Shrewsbury Airport Taxi
            </a>
          </li>
          <li className="mb-[10px] ">
            <a className="font-[700] text-[18px]  text-[#FF9F43] " href="">
              Leicester Airport Taxi
            </a>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
};
export default AreaWeCover;
