import { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Main(props) {
    const mapApiKey = 'AIzaSyCXXZ9VqXu2DV6UfyOLKERn074at4mY8BI';
    const [start, setStart] = useState({
        lat: "",
        lng: "",
        name: "",
    });
    const [end, setEnd] = useState({
        lat: "",
        lng: "",
        name: "",
    });

    const pax = [
        { value: "1", label: "Passenger", isSelected: true, isDisabled: true },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
    ];
    const lug = [
        { value: "0", label: "Luggage", isSelected: true, isDisabled: true },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
    ];

    const infant = [
        { value: "0", label: "Infant seat (0-15 Mon)", isDisabled: true },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
    ];
    const regular = [
        { value: "0", label: "Regular Seat (15-36 Mon)", isDisabled: true },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
    ];
    const booster = [
        { value: "0", label: "Booster Seat (Above 3 yrs)", isDisabled: true },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
    ];


    useEffect(() => {
        setStart({
            lat: 42.3656132,
            lng: -71.0095602,
            name: "Boston Logan International Airport",
        });
    }, [setStart]);

    // Errors
    const [startError, setStartError] = useState("");
    const [endError, setEndError] = useState("");
    const [dateError, setDateError] = useState("");

    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState("from_airport");
    const [showHideChildSeat, setShowHideChildSeat] = useState(false);

    const radios = [
        {
            name: "From Airport",
            value: "from_airport",
            //   img: FromAirport,
        },
        {
            name: "To Airport",
            value: "to_airport",
            //   img: ToAirport,
        },
    ];

    // Date & TIme
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    const handleChildSeat = (event) => {
        setShowHideChildSeat(event)
    };

    const handleFromTo = (e) => {
        if (e === "to_airport") {
            setRadioValue("to_airport");
            setEnd({
                lat: 42.3656132,
                lng: -71.0095602,
                name: "Boston Logan International Airport",
            });
            setStart({ lat: "", lng: "", name: "" });
        } else {
            setRadioValue("from_airport");
            setStart({
                lat: 42.3656132,
                lng: -71.0095602,
                name: "Boston Logan International Airport",
            });
            setEnd({ lat: "", lng: "", name: "" });
        }
        setEndError("");
        setStartError("");
    };
    const formatDate = (d) => {
        var date = new Date(d)
        var dd = date.getDate();
        var mm = date.getMonth() + 1;
        var yyyy = date.getFullYear();
        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm };
        return d = mm + '/' + dd + '/' + yyyy
    }
    const formatTime = (d) => {
        var dt = new Date(d)
        var hours = dt.getHours(); // gives the value in 24 hours format
        var minutes = dt.getMinutes();
        var time24 = hours + ':' + minutes;

        const [hourString, minute] = time24.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }
    // Form Submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (start.name && end.name) {
            var pickDateTime = e.target[4].value;
            var pickDate = formatDate(pickDateTime);
            var pickTime = formatTime(pickDateTime);
            const data = {
                service_type: radioValue,
                from: start.name,
                to: end.name,
                start_lat: start.lat,
                start_lng: start.lng,
                end_lat: end.lat,
                end_lng: end.lng,
                pick_date: pickDate,
                pick_time: pickTime,
                passengers: e.target[5].value,
                luggage: e.target[6].value,
                infant_seat: e.target[7] ? e.target[7].value : "0",
                regular_seat: e.target[8] ? e.target[8].value : "0",
                booster_seat: e.target[9] ? e.target[9].value : "0",
            };

            window.location.href = `https://booking.worcesterairporttaxi.com/quote?service_type=${data.service_type}&from=${data.from}&start_lat=${data.start_lat}&start_lng=${data.start_lng}&to=${data.to}&end_lat=${data.end_lat}&end_lng=${data.end_lng}&pick_date=${data.pick_date}&pick_time=${data.pick_time}&passengers=${data.passengers}&luggage=${data.luggage}&infant_seat=${data.infant_seat}&regular_seat=${data.regular_seat}&booster_seat=${data.booster_seat}`;
        } else {
            if (!start.name) {
                setStartError("Please fillup Pickup Address");
            }

            if (!end.name) {
                setEndError("Please fillup Drop off Address");
            }
        }
    };
    return (
        <div id="main" className="mainBackground w-[calc(100vw - 100%)]bg-[#f5f5f5]">
            <div>{props.children}</div>
            <div className="h-[calc(100%-80px)] flex xsm:flex-col sm:flex-col items-center xsm:gap-[20px] sm:gap-[20px] gap-[101px] xsm:bg-white sm:bg-white">
                <div>
                    <img src="./images/mainImage.png" alt="" />
                </div>

                <div className="w-[346px] mt-[40px] ml-[60px] quote__wrapper">
                    <h1 className="font-[700] text-[clamp(42px,3.1944444444444446vw,46px)] xsm:leading-[63px] sm:leading-[63px] leading-[69px] text-[#ffffff] xsm:text-[#1A1A1A] sm:text-[#1A1A1A]">Get a Taxi Now</h1>
                    <div className="flex gap-[25px] items-center">
                        <img className="xsm:bg-[#1A1A1A] sm:bg-[#1A1A1A]" src="./images/mainPhoneIcon.png" alt="" />
                        <h2 className="font-[500] text-[clam(18px,1.6666666666666667vw,24px)] leading-[36px] text-[#ffffff] xsm:text-[#1A1A1A] sm:text-[#1A1A1A]">1-800-123-1234</h2>
                    </div>
                    <p className="mt-[16px] mb-[16px] w-[291px] font-[400] text-[12px] leading-[18px] text-[#fff] xsm:text-[#1A1A1A] sm:text-[#1A1A1A]">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae vel convallis pulvinar duis.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="w-[316.71px] m-auto ">
                            <div className="flex text-[#fff]" >
                                <button className="flex-1 flex items-center justify-center gap-[17px] bg-[#115FA6] h-[43px] rounded-tl-[10px] rounded-bl-[10px]" onClick={(e) => handleFromTo('from_airport')}><img src="./images/mainFromAirport.png" alt="" /> From Airport</button>
                                <button className="flex-1 flex items-center justify-center gap-[28px] bg-[#FF9F43] h-[43px] rounded-tr-[10px] rounded-br-[10px]" onClick={(e) => handleFromTo('to_airport')}><img src="./images/mainToAirport.png" alt="" />To Airport</button>
                            </div>
                            <div className="relative">
                                {radioValue == "from_airport" ? (
                                    <input className="mt-[19px] w-full h-[44px] rounded-[6px] text-[#323437] bg-[#EAEBEC] indent-[13px] text-[14px] font-[500] leading-[21px] tracking-[1px] pr-[30px]" type={"text"} placeholder="Pick up Address" value="Boston Logan International Airport (BOS), Boston, MA, USA" readOnly />
                                ) : (<ReactGoogleAutocomplete
                                    apiKey={mapApiKey}
                                    placeholder="Pickup Address"
                                    className="mt-[19px] w-full h-[44px] rounded-[6px] text-[#323437] bg-[#EAEBEC] indent-[13px] text-[14px] font-[500] leading-[21px] tracking-[1px] pr-[30px]"
                                    onPlaceSelected={(place) => {
                                        setStart({
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng(),
                                            name: place.formatted_address,
                                        });
                                    }}
                                    options={{
                                        types: ["geocode", "establishment"],
                                        componentRestrictions: { country: ["us"] },
                                    }}
                                    onClick={() => setStartError("")}
                                />
                                )}
                                <p className="text-danger">{startError}</p>

                                {radioValue == "to_airport" ? (
                                    <input className="mt-[30px] w-full h-[44px] rounded-[6px] !text-[#323437] bg-[#EAEBEC] indent-[13px] text-[14px] font-[500] leading-[21px] tracking-[1px] pr-[30px]" type={"text"} placeholder="Drop Off Address" value="Boston Logan International Airport (BOS), Boston, MA, USA" readOnly />
                                ) : (<ReactGoogleAutocomplete
                                    apiKey={mapApiKey}
                                    placeholder="Drop Off Address"
                                    className="mt-[30px] w-full h-[44px] rounded-[6px] !text-[#323437] bg-[#EAEBEC] indent-[13px] text-[14px] font-[500] leading-[21px] tracking-[1px] pr-[30px]"
                                    onPlaceSelected={(place) => {
                                        setEnd({
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng(),
                                            name: place.formatted_address,
                                        });
                                    }}
                                    options={{
                                        types: ["geocode", "establishment"],
                                        componentRestrictions: { country: ["us"] },
                                    }}
                                    onClick={() => setEndError("")}
                                />
                                )}
                                <p className="text-danger">{endError}</p>

                                <img className="absolute top-[25%] left-[-10%]" src="./images/mainProgress.png" alt="" />
                            </div>
                            <input className="mt-[20px] w-full h-[44px] rounded-[6px] !text-[#323437] bg-[#EAEBEC] indent-[13px] text-[14px] font-[500] leading-[21px] tracking-[1px] pr-[30px]" type={"datetime-local"}></input>
                            <div className="flex mt-[20px] gap-[11px]">
                                <select className="flex-1 h-[44px] rounded-[6px] !text-[#323437] bg-[#EAEBEC] indent-[13px] text-[14px] font-[500] leading-[21px] tracking-[1px] pr-[30px]">
                                    {pax.map(p => (
                                        <option value={p.value}>{p.label}</option>
                                    ))}
                                </select>

                                <select className="flex-1 h-[44px] rounded-[6px] !text-[#323437] bg-[#EAEBEC] indent-[13px] text-[14px] font-[500] leading-[21px] tracking-[1px] !pr-[30px]">
                                    {lug.map(l => (
                                        <option value={l.value}>{l.label}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex mt-[24px] items-center gap-[21px]">
                                <img src="./images/mainPlus.png" alt="" />
                                <p className="font-[500] text-[#ffffff] text-[14px] ;leading-[21px] " onClick={(e) => handleChildSeat(!showHideChildSeat)}>Add Child & Infant</p>
                            </div>

                            <div className="childseat-wrapper" style={{ display: showHideChildSeat ? '' : 'none' }}>
                                <select className="">
                                    {infant.map(i => (
                                        <option value={i.value}>{i.label}</option>
                                    ))}
                                </select>
                                <select className="">
                                    {regular.map(i => (
                                        <option value={i.value}>{i.label}</option>
                                    ))}
                                </select>
                                <select className="">
                                    {booster.map(i => (
                                        <option value={i.value}>{i.label}</option>
                                    ))}
                                </select>
                            </div>

                            <button className="mb-[40px] mt-[25px] m-auto flex items-center justify-center gap-[13px] w-[180px] h-[39px] bg-[#FF9F43] rounded-[10px] text-[14px] font-[400] leading-[21px] text-[#FFFFFF]" type="submit"><img src="./images/mainSearch.png" />Choose Vehicle</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}