import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer-wrapper w-[calc(100vw - 100%)] bg-[#273A89]">
      <div className="relative flex xsm:flex-col-reverse gap-[8.055555555555555vw] xsm:gap-0 justify-center items-center w-full bg-[#273A89] ">
        <img
          className="xsm:hidden sm:hidden absolute top-0 right-[0%] z-0"
          src="./images/oval.png"
        />
        <img
          className="xsm:block sm:block hidden absolute top-0 right-[10%] z-0"
          src="./images/small-oval.png"
        />
        <div className="relative z-10 mt-[6.944444444444445vw] pl-[20px] pr-[20px] mb-[74px] call__wrapper">
          <p className=" font-[300] text-[clamp(12px,1.25vw,18px)] xsm:leading-[18px] sm:leading-[18px] leading-[27px] text-[#FFFFFF]">
            We are Ready to take your call 24 hours, 7 Days !
          </p>
          <h2 className="mt-[2px] flex items-center gap-[1.6666666666666667vw] font-[700] text-[clamp(18px,3.3333333333333335vw,48px)] xsm:leading-[27px] sm:leading-[27px] leading-[72px] text-[#FFFFFF]">
            <img
              className="min-w-[15px] w-[3.4722222222222223vw]"
              src="./images/whatsapp.png"
            />
            Call Now : <a href="tel:5085191755">(508) 519-1755</a>
          </h2>
          <h2 className="mt-[1.1111111111111112vw] font-[600] text-[clamp(12px,2.7777777777777777vw,40px)] xsm:leading-[0px] sm:leading-[0px] leading-[48px] text-[#FFFFFF]">
            With Over 150 Partners Location
          </h2>
        </div>
        <div className="relative z-10">
          <img
            className="w-[41.59722222222222vw] min-w-[273px]"
            src="./images/carAndDots.png"
          />
        </div>
      </div>
      <div className="footer__menus w-[95%] bg-[#273A89] flex xsm:flex-col flex-wrap items-center justify-between   pt-[46px] m-auto">
        <ul className="xsm:w-[90%] xsm:m-auto xsm:flex-1 xsm:flex xsm:flex-col xsm:items-left xsm:place-content-around text-[16px] text-[rgba(255,255,255,0.6)] leading-[28px] self-start">
          <li className="flex">
            <h1 className="flex items-center gap-[14px] font-[700] mb-[] text-[24px] text-[#FFFFFF] xsm:leading-[0px] sm:leading-[0px] leading-[28.18px]">
              <img src="./images/Logo.png" />
            </h1>
          </li>
          <li className="  xsm:mt-[2.638888888888889vw] xsm:mb-[2.0833333333333335vw] w-[400px] mt-[20px]  text-left xsm:text-left xsm:w-[100%] sm:w-[100%] xsm:min-w-[100%] sm:min-w-[100%]  ">
            Worcester Airport Taxi and Car Service purvey the most economical,
            convenient and safe way to get to and from the Worcester airport.
            <br />
            With flexible hours and skilled, professional drivers, we will get
            you where you need to be, right on time and in total comfort.
          </li>
        </ul>

        <ul className=" flex flex-col gap-[16px] text-[16px] text-[rgba(255,255,255,0.6)] leading-[28px] self-start">
          <li className=" top-gap  text-[rgba(255,255,255,1)] font-[600] mb-[8px]">
            Quick Links
          </li>
          <li>
            <Link to="/"> Home </Link>
          </li>
          <li>
            <Link to="/about"> About us</Link>
          </li>
          <li>
            <Link to="/service-area"> Service Area</Link>
          </li>
          <li>
            <Link to="/fleet"> Fleet</Link>
          </li>
          <li>
            <Link to="/contact"> Contact us</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions"> Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy-policy"> Privacy policy</Link>
          </li>
        </ul>

        <ul className=" flex flex-col gap-[16px] text-[16px] text-[rgba(255,255,255,0.6)] leading-[28px] self-start">
          <li className="top-gap text-[rgba(255,255,255,1)] font-[600] mb-[8px]">
            Major Service Area
          </li>
          <li>
            <Link to="/worcester-airport-taxi"> Worcester Airport Taxi</Link>
          </li>
          <li>
            <Link to="/auburn-airport-taxi"> Auburn Airport Taxi</Link>
          </li>
          <li>
            <Link to="/shrewsbury-airport-taxi"> Shrewsbury Airport Taxi</Link>
          </li>
          <li>
            <Link to="/leicester-airport-taxi"> Leichester Airport Taxi</Link>
          </li>
        </ul>

        <ul className="xsm:w-[90%] xsm:m-auto xsm:mt-[30px] xsm:flex-1 flex flex-col gap-[16px] text-[16px] text-[rgba(255,255,255,1)] leading-[28px] self-start">
          <li className="top-gap text-[rgba(255,255,255,1)] font-[600] xsm:mb-0 mb-[8px]">
            Get in toch
          </li>
          <li className="w-[24.51388888888889vw] min-w-[313px]">
            <span className="font-[700]">Office:</span> Worcesther, MA
          </li>
          <li>
            <span className="font-[700]">Phone: </span>
            <a href="tel:5085191755">(508) 519-1755</a>
          </li>
          <li>
            <span className="font-[700]">Email: </span>
            <a href="mailto:info@worcesterairporttaxi.com"> 
              info@worcesterairporttaxi.com
            </a>
          </li>
          <li className="flex-1 flex xsm:justify-center gap-[24px] xsm:mt-0 mt-[20px] mb-[20px]">
            <a
              href="https://www.facebook.com/Worcesterairporttaxi/"
              target="_blank"
            >
              <img src="./images/facebook.png" />
            </a>
            <a href="https://twitter.com/taxi_worcester" target="_blank">
              <img src="./images/twitter.png" />
            </a>
            <a href="https://www.pinterest.com/social8735/" target="_blank">
              <img src="./images/pint.svg" />
            </a>
          </li>
        </ul>
      </div>
      <p className="font-[500] text-[12px] leading-[26px] text-[#fff] mt-[35px]  xsm:w-[100%] sm:w-[100%] xsm:min-w-[100%] sm:min-w-[100%]   pb-[14px] text-center">
        Copyright © {new Date().getFullYear()} Worchester Airport Car & Taxi
        Service. All Rights Reserved.
      </p>
    </div>
  );
}
