import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Testimonial from "../Components/Testimonial";

const ServiceArea = () => {
  return (
    <>
      <Navbar
        SeoTitle="Book Worcester taxi to Boston Logan International airport and Nearby Cities"
        SeoDesc="Arrival at Boston Logan airport or Worcester airport in style with Worcester Airport Taxi. We are your #1 transportation company in Worcester. Call us today (508) 519-1755"
      />
      <div className="w-[calc(100vw - 100%)] pt-[70px]   flex flex-col items-center bg-[#f5f5f5]">
        <h1 className="text-center font-[700] mb-[30px] text-[36px] leading-[54px] ">
          SERVICE AREA
        </h1>
        <h4 className="font-[500] pl-[35px] pr-[35px]  text-[24px] mb-[30px] leading-[54px] ">
          Service area: Worcester to Boston Logan International airport and
          nearby cities
        </h4>
        <p className="w-[90%] mb-[20px]">
          Worcester, MA: Getting to Worcester Airport or looking for a taxi
          service from Worcester, MA to Boston Logan Airport. Get comfortable
          and affordable to and from Worcester taxi service to Boston Logan
          international airport or between Worcester, Suburbs, and airports.
        </p>
        <p className="w-[90%] mb-[20px]">
          Upon your arrival at Boston Logan airport or Worcester airport, our
          taxi is waiting for you outside of the terminal building. Our meet &
          greet service is especially for corporate clients and first-time
          visitors to a foreign country. From the moment you get in touch, we
          will take care of everything to make sure that your travel to and from
          the airport is as smooth as you desire.
        </p>
      </div>
      <div className="w-[calc(100vw - 100%)] pt-[70px] pb-[70px]    bg-[#fff]">
        <h4
          style={{ margin: "0 auto" }}
          className=" w-[90%] text-[#273A89]  font-[500] text-[24px] pb-[30px] leading-[34px] "
        >
          Frequently provided taxi cab, town car, suv, van and minivan services
          from the following area to Boston Logan International airport.
        </h4>
        <ul
          class="list-disc  w-[90%] mt-[40px] pl-[20px]"
          style={{ margin: "0 auto", columnCount: "2" }}
        >
          <li className="mb-[10px] ">
            <a className="font-[700] text-[18px]  text-[#FF9F43] " href="">Worcester Airport Taxi</a>
          </li>
          <li className="mb-[10px] ">
            <a className="font-[700] text-[18px]  text-[#FF9F43] "  href="">Auburn Airport Taxi</a>
          </li>
          <li className="mb-[10px] ">
            <a className="font-[700] text-[18px]  text-[#FF9F43] "  href="">Shrewsbury Airport Taxi</a>
          </li>
          <li className="mb-[10px] ">
            <a className="font-[700] text-[18px]  text-[#FF9F43] "  href="">Leicester Airport Taxi</a>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
};
export default ServiceArea;
